import { blogData } from "@/data/blog";
import BlogItem from "../blog/BlogItem";
import Btn from "../uikit/Btn";
import SectionHeaning from "../uikit/SectionHeaning";




function Blogs() {
  return (
    <section>
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 ">
        <div className="flex flex-col items-center">
          <SectionHeaning
            title={"Latest blogs"}
            subTitle={"Unleashing Creative Insights"}
            description={
              " Explore the future of design through Youcad's lens. Unleash creative insights for a transformative journey."
            }
          />
          <div className="mb-6 grid grid-cols-1 justify-items-center gap-8 sm:justify-items-stretch md:mb-10 md:grid-cols-3 md:gap-4 lg:mb-12">
            {blogData.slice(0,6).map((e, i) => (
              <BlogItem key={i} {...blogData[i]} />
            ))}
          </div>
          <Btn text={"View More Articles"} link={"/blog"} />
        </div>
      </div>
    </section>
  );
}

export default Blogs;
